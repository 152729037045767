import * as Popover from '@radix-ui/react-popover'
import T from 'prop-types'
import { useState } from 'react'

import BEMHelper from '../../helpers/bem'
import { blockToPlainText } from '../../helpers/utils'
import Icon from '../Icon'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

function Content({ value }) {
  if (typeof value === 'string') {
    return value
  }
  return blockToPlainText(value)
}

export default function InlineReference({ children, mark, sourceUrl }) {
  const [open, setOpen] = useState(false)

  if (!mark.content) {
    return children
  }

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger {...bem('toggle')}>{children}</Popover.Trigger>
      <Popover.Portal>
        <>
          <Popover.Content {...bem('content')} align="end" sideOffset={2} asChild>
            <figure>
              <Popover.Close aria-label="Lukk" />
              <blockquote>
                <Content value={mark.content} />
              </blockquote>
              {sourceUrl && (
                <figcaption>
                  <cite>
                    {' '}
                    <a href={sourceUrl} target="_blank" rel="noreferrer">
                      Kilde <Icon icon="external" />
                    </a>
                  </cite>
                </figcaption>
              )}
            </figure>
          </Popover.Content>
          {open && <div {...bem('backdrop')} />}
        </>
      </Popover.Portal>
    </Popover.Root>
  )
}

InlineReference.propTypes = {
  children: T.any.isRequired,
  sourceUrl: T.string,
  mark: T.object,
}

export const PropTypes = InlineReference.propTypes
