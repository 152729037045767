export default function docToUrl({ _type = '', slug = '' } = {}) {
  const routes = {
    frontpage: () => '/',
    about: () => '/om-oss',
    staticPage: () => `/${slug}`,
    article: () => `/artikkel/${slug}`,
    student: () => `/elevopplegg/${slug}`,
    presentation: () => `/presentasjon/${slug}`,
    quiz: () => `/quiz/${slug}`,
    topic: () => `/tema/${slug}`,
    lesson: () => `/undervisningsopplegg/${slug}`,
  }

  return routes[_type || '']?.() ?? (slug && `/${slug}`) ?? '#'
}
