import React from 'react'
import T from 'prop-types'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

export function ListItem({ children }) {
  return <li {...bem('item')}>{children}</li>
}

ListItem.propTypes = {
  children: T.any.isRequired,
}

export const ListItemPropTypes = ListItem.propTypes

export function List({ children, type, className }) {
  const Node = type || 'ul'

  return (
    <Node
      {...bem(
        '',
        { numbered: type === 'ol', normal: type !== 'ol' },
        className,
      )}
    >
      {children}
    </Node>
  )
}

List.propTypes = {
  children: T.any.isRequired,
  type: T.string,
  className: T.string,
}

export const ListPropTypes = List.propTypes
