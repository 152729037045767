import BlockContent from '@sanity/block-content-to-react'
import T from 'prop-types'
import { useRef, useState } from 'react'

import { dataset, projectId } from '@/api/sanity'
import BEMHelper from '@/helpers/bem'
import blockContentSerializers from '@/helpers/block-content-serializers'
import Icon from '../Icon'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

interface Props {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blocks?: any
  children?: React.ReactNode
  defaultExpanded?: boolean
  type?: string
  node?: keyof JSX.IntrinsicElements
}

export default function Accordion({
  title,
  blocks,
  defaultExpanded = false,
  children,
  type,
  node,
}: Props) {
  const Node = node || 'aside'
  const wrapperRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState<string | null>(null)
  const [expanded, setExpanded] = useState(defaultExpanded)
  const [doneAnimating, setDoneAnimating] = useState(false)

  const toggle = () => {
    const newState = !expanded
    setExpanded(newState)
    setDoneAnimating(false)

    if (newState && wrapperRef.current) {
      wrapperRef.current.focus()
      setHeight(`${wrapperRef.current.scrollHeight}px`)
    }
  }

  const handleTransitionEnd = () => {
    if (expanded) {
      setDoneAnimating(true)
    }
  }

  return (
    <Node {...bem('', { expanded, collapsed: !expanded, [type || '']: type })} data-accordion>
      <button type="button" {...bem('toggle')} onClick={toggle} aria-expanded={expanded}>
        <h2 {...bem('title')}>{title}</h2>
        <span {...bem('indicator')}>
          <Icon icon="chevron" direction="down" {...bem('icon')} />
        </span>
      </button>
      <div
        {...bem('wrapper', { expanded: doneAnimating })}
        ref={wrapperRef}
        style={expanded ? { maxHeight: height ?? '' } : { maxHeight: '0px' }}
        onTransitionEnd={handleTransitionEnd}
        tabIndex={-1}
      >
        <div {...bem('content')}>
          {children}
          {blocks && (
            <BlockContent
              blocks={blocks}
              serializers={blockContentSerializers}
              dataset={dataset}
              projectId={projectId}
            />
          )}
        </div>
      </div>
    </Node>
  )
}

Accordion.propTypes = {
  title: T.string.isRequired,
  blocks: T.any,
  children: T.any,
  defaultExpanded: T.bool,
  node: T.string,
  type: T.string,
}
