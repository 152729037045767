/* eslint-disable @typescript-eslint/no-explicit-any */
import { atom } from 'jotai'

export const isLoggedIn = atom<boolean>(false)

type TopicTheme = {
  _id: string
  light?: string | null
  background?: string | null
  promoted?: string | null
  dark?: string | null
  illustration: {
    aspect: number
    path: string
    webp?: string | null
  }
}

type Topic = {
  _id: string
  slug: string
  title: string
  preamble?: string | null
  topicTheme: TopicTheme
}

export const settings = atom<{
  menu?: Array<
    | {
        _key: string
        _type: 'menuItem'
        title: string
        href: string
      }
    | {
        _key: string
        _type: 'topics'
        title: string
      }
  >
  searchPlaceholders?: string[]
  footer?: any
  topics?: Topic[]
}>()
