/* eslint-disable react/display-name */
import { Text } from '@react-pdf/renderer'
import BlockContent from '@sanity/block-content-to-react'

import Accordion from '@/components/Accordion'
import InlineReference, { PropTypes as InlineReferenceProps } from '../components/InlineReference'
import Link from '../components/Link'
import { List, ListItem, ListItemPropTypes, ListPropTypes } from '../components/List'
import Quote from '../components/Quote'
import docToUrl from './docToUrl'

const PdfBlockRenderer = (props) => {
  const { style = 'normal' } = props.node

  if (style === 'normal') {
    return <Text>{props.children}</Text>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}
const BlockRenderer = (props) => {
  const { style = 'normal' } = props.node

  if (style === 'blockquote') {
    return <Quote>{props.children}</Quote>
  }

  // Fall back to default handling
  return BlockContent.defaultSerializers.types.block(props)
}

export const pdfSerializers = {
  types: { block: PdfBlockRenderer },
  marks: {
    em: (props) => <Text style={{ fontStyle: 'italic' }}>{props.children}</Text>,
    strong: (props) => <Text style={{ fontWeight: 'bold' }}>{props.children}</Text>,
  },
}

const serializers = {
  list({ type, ...props }) {
    return <List type={type === 'bullet' ? 'ul' : 'ol'} {...props} />
  },
  listItem(props) {
    return <ListItem {...props} />
  },
  types: {
    block: BlockRenderer,
    accordion: ({ node }) => {
      return <Accordion {...node} />
    },
  },
  marks: {
    definition(props) {
      return <InlineReference {...props} />
    },
    internalLink(value) {
      return <Link href={docToUrl(value.mark.internalLink)}>{value.children}</Link>
    },
  },
}

serializers.list.propTypes = ListPropTypes
serializers.listItem.propTypes = ListItemPropTypes
serializers.marks.definition.propTypes = InlineReferenceProps

export default serializers
