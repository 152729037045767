import React from 'react'
import T from 'prop-types'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = new BEMHelper(styles)

export default function Quote({ children }) {
  return <blockquote {...bem('')}>{children}</blockquote>
}

Quote.propTypes = {
  children: T.any,
}
